<template>
    <div class="page1">
        <div class="box-height" :class="{'active':isMore}">
            <contractDetail></contractDetail>
        </div>
        <div class="tc mt30 mb20 bgf4f4f5" @click="showMore" @tap="isMore=!isMore">
            <div type="text" class="c9 pt10 pb12 fs15 pointer" v-if="!isMore"><i class="el-icon-arrow-down"></i>展开更多
            </div>
            <div type="text" class="c9 pt10 pb12 fs15 pointer" v-else><i class="el-icon-arrow-up"></i>收起</div>
        </div>


        <div class="title">交费列表</div>
        <ETable :tableTitle="payTableTitle" :tableData="payOrders" :needPagination="false">
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <div>
                        <el-button type="text" @click="showPayList(scope.row.items,scope.$index)">
                            查看明细
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </ETable>

        <div class="title">未交费列表</div>
        <ETable :tableTitle="unpayTableTitle" :tableData="unpayOrders" :needPagination="false" ref="unPayFrom">
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <div>
                        <el-button type="text" @click="showUnPayList(scope.row.items,scope.$index)">
                            查看明细
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </ETable>

        <div class="title">其他收费项目</div>
        <el-form class="demo-form-inline" size="small" label-width="150px" label-position="left" :model="dialogForm"
                 :rules="formRules" ref="form1" :inline="true">
            <el-form-item label="收费项目" prop="takeReturnProjectId">
                <el-select v-model="dialogForm.takeReturnProjectId" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSummit(otherForm,dialogForm.takeReturnProjectId)" size="small">添加
                </el-button>
            </el-form-item>
        </el-form>

        <!--点击添加出现的form表单-->
        <div>
            <el-form class="demo-form-inline" size="small" label-width="150px" label-position="left" :model="form"
                     :rules="formRules2" ref="form">
                <div v-for="(item,index) in otherItems" :key="index" class="bdt pt20">
                    <el-row :gutter="10">
                        <el-col :span="9">
                            <el-form-item label="收费项目" prop="takeReturnProjectId">
                                <el-select v-model="item.takeReturnProjectId" placeholder="" disabled="">
                                    <el-option v-for="(item) in options" :key="item.value" :label="item.label"
                                               :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="金额" prop="payAmount">
                                <el-input v-model="item.payAmount" placeholder=""
                                          @blur="getRefundNum(index)"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-form-item label="">
                                <el-button type="danger" @click="remove(index)" size="small">移除</el-button>
                            </el-form-item>
                        </el-col>
                        <el-col :span="9">
                            <el-form-item label="费用开始时间" prop="feeBeginDate">
                                <el-date-picker
                                        v-model="item.feeBeginDate"
                                        type="date"
                                        placeholder="选择日期"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="9">
                            <el-form-item label="费用结束时间" prop="feeEndDate">
                                <el-date-picker
                                        v-model="item.feeEndDate"
                                        type="date"
                                        placeholder="选择日期"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="9">
                            <el-form-item label="备注" prop="remark">
                                <el-input type="textarea" v-model="item.remark" placeholder="备注"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
        </div>

        <!--    交费合计-->
        <el-row class="tr fs15">
            交费：共{{obj.feeNum}}笔，合计：{{obj.amount }}元
            <el-divider></el-divider>
        </el-row>


        <!--    是否需要财务确认-->
        <EForm :formColumns="formColumns2" :rowSize="2" :optionsBtn="false" :actionBtn="true" :formData="form"
               ref="otherForm" :searchFlag="false" labelPosition="left" labelWidth="150px"
               :formRules="formRules2">
        </EForm>

        <!--    确认退组按钮-->
        <el-row style="margin-top: 20px">
            <el-button type="primary" @click="saveData" size="small" :disabled="saveDisabled">确认退租</el-button>
            <el-button type="primary" @click="goBack" size="small">取消</el-button>
        </el-row>

        <!--    交费列表table弹窗-->
        <EDialog :dialogVisible="paydialogVisible" title="详情" @handleClose="paycancelDialog" width="60%"
                 @handleClick="payhandleClick">
            <ETable :tableTitle="payDetailTableTitle" :tableData="payOrdersItems" :needPagination="false">
                <div slot="returnAmount" slot-scope="scope">
                    <el-input v-model="scope.data.returnAmount" @blur="()=>{
            if(scope.data.returnAmount>0||Math.abs(scope.data.returnAmount)>Math.abs(scope.data.payAmount)||(scope.data.returnAmount!=0&&scope.data.returnAmount.substring(0,1)==0)){
              $message.error('输入负数，并且小于等于收款金额')
              scope.data.returnAmount=0
              return false
            }
          }"></el-input>
                </div>
            </ETable>
            <!--    是否全部退款-->
            <EForm :formColumns="formColumns3" :rowSize="2" :optionsBtn="false" :actionBtn="true" :formData="form"
                  ref="otherForm" :searchFlag="false" labelPosition="left" labelWidth="150px">
            </EForm>
        </EDialog>


        <!--    未交费列表table弹窗-->
        <EDialog :dialogVisible="unpaydialogVisible" title="详情" @handleClose="unpaycancelDialog" width="60%"
                 @handleClick="unpayhandleClick">
            <ETable :tableTitle="unpayDetailTableTitle" :tableData="unpayOrdersItems" :needPagination="false"
                    @handleSelect="handleSelect"
                    :needSelect="true"
                    ref="unpayDetailTable">

            </ETable>
        </EDialog>

    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import infoList from '@/components/infoList'
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";
  import contractDetail from './contractDetail'
  import * as math from 'mathjs'


  export default {
    name: 'refundRent',
    mixins: [otherMixin],
    data() {
      return {
        isMore: false,
        exportData: {},
        dialogVisible: false,
        paydialogVisible: false,
        unpaydialogVisible: false,
        labelPosition: 'right',
        labelWidth: '100px',
        formColumns: [
          {
            title: '收费项目',
            property: 'takeReturnProjectId',
            type: 'select',
            placeholder: '商户名称',
            options: [],
            show: true
          },
        ],
        options: [],
        formColumns2: [
          {
            title: '是否需要财务确认',
            property: 'isConfirm',
            type: 'radio',
            placeholder: '',
            options: [
              {
                label: '是',
                value: '1'
              },
              {
                label: '否',
                value: '2'
              },
            ],
            show: true
          },
        ],
        formColumns3: [
          {
            title: '退费方式',
            property: 'refundStatus',
            type: 'radio',
            placeholder: '',
            options: [
              {
                label: '部分退费',
                value: '1'
              },
              {
                label: '全部退费',
                value: '2'
              },
            ],
            show: true
          },
        ],
        form: {
          takeReturnProjectId: '',
          contractId: '',
          tenantName: '',
          phone: '',
          signPerson: '',
          businessScopeId: '',
          idCard: '',
          tenantType: '',
          birthPlace: '',
          idCardPath: '',
          isConfirm: '1',
          refundStatus: '0',
          relationOrderId: 0,
        },
        payTableTitle: [
          {
            label: '交费单号',
            prop: 'orderNum'
          },
          {
            label: '收费时间',
            prop: 'boothName'
          },
          {
            label: '区域',
            prop: 'areaName'
          },
          {
            label: '位置',
            prop: 'placeName',
          },
          {
            label: '摊位号',
            prop: 'boothCode'
          },
          {
            label: '商户名称',
            prop: 'tenantName'
          },
          {
            label: '总金额',
            prop: 'totalAmount'
          },
          {
            label: '费用笔数',
            prop: 'feeNum'
          },
          {
            label: '财务确认人',
            prop: 'confirmPersonName'
          },
          {
            label: '收费人',
            prop: 'chargePersonName'
          },
          {
            label: '转账金额',
            prop: 'transferAmount'
          },
          {
            label: '现金金额',
            prop: 'cashAmount'
          },
          {
            label: '备注',
            prop: 'remark'
          },
        ],
        payDetailTableTitle: [
          {
            label: '收费项目名称',
            prop: 'takeReturnProjectName'
          },
          {
            label: '年',
            prop: 'year'
          },
          {
            label: '月',
            prop: 'month'
          },
          {
            label: '费用起始日期',
            prop: 'feeBeginDate',
          },
          {
            label: '费用截止日期',
            prop: 'feeEndDate'
          },
          {
            label: '收费金额',
            prop: 'payAmount'
          },
          {
            label: '第X期',
            prop: 'periods'
          },
          {
            label: '是否缴费',
            prop: 'isPayCn'
          },
          {
            label: '退费金额',
            prop: 'returnAmount',
            type: 'slot'
          },
          {
            label: '备注',
            prop: 'remark'
          },
        ],
        unpayTableTitle: [
          {
            label: '交费单号',
            prop: 'orderNum'
          },
          {
            label: '区域',
            prop: 'areaName'
          },
          {
            label: '位置',
            prop: 'placeName',
          },
          {
            label: '摊位号',
            prop: 'boothCode'
          },
          {
            label: '商户名称',
            prop: 'tenantName'
          },
          {
            label: '总金额',
            prop: 'totalAmount'
          },
          {
            label: '费用笔数',
            prop: 'feeNum'
          },
          {
            label: '财务确认人',
            prop: 'confirmPersonName'
          },
          {
            label: '收费人',
            prop: 'chargePersonName'
          },
          {
            label: '转账金额',
            prop: 'transferAmount'
          },
          {
            label: '现金金额',
            prop: 'cashAmount'
          },
          {
            label: '备注',
            prop: 'remark'
          },
        ],
        unpayDetailTableTitle: [
          {
            label: '收费项目名称',
            prop: 'takeReturnProjectName'
          },
          {
            label: '年',
            prop: 'year'
          },
          {
            label: '月',
            prop: 'month'
          },
          {
            label: '费用起始日期',
            prop: 'feeBeginDate',
          },
          {
            label: '费用截止日期',
            prop: 'feeEndDate'
          },
          {
            label: '收费金额',
            prop: 'payAmount'
          },
          {
            label: '第X期',
            prop: 'periods'
          },
          {
            label: '是否缴费',
            prop: 'isPayCn'
          },
          {
            label: '备注',
            prop: 'remark'
          },
        ],
        payOrders: [],//合同已交费单信息
        payOrdersItems: [],//合同未交费单信息明细
        unpayOrders: [],//合同未缴费单信息
        unpayOrdersItems: [],//合同未缴费单信息明细
        otherItems: [],
        otherForm: { //otherItems里的每个form对象
          feeBeginDate: '',
          feeEndDate: '',
          payAmount: '',
          remark: '',
          takeReturnProjectId: '',
          takeReturnProjectName: '',
        },
        tempSelectData: [],//临时保存勾选的数据
        otherFormColumns: [
          {
            title: '收费项目',
            type: 'select',
            property: 'takeReturnProjectId',
            placeholder: '',
            show: true,
            disabled: true,
            options: []
          },
          {
            title: '金额',
            type: 'input',
            property: 'payAmount',
            placeholder: '金额',
            show: true,
          },
          {
            title: '费用开始时间',
            type: 'datePicker',
            property: 'feeBeginDate',
            show: true,
            pickerOptions: {
              disabledDate: (time) => {
                let feeEndDate = this.otherForm.feeEndDate
                if (feeEndDate)
                  return time.getTime() > new Date(feeEndDate).getTime()
              }
            },
          },
          {
            title: '费用结束时间',
            type: 'datePicker',
            property: 'feeEndDate',
            show: true,
            pickerOptions: {
              disabledDate: (time) => {
                let feeBeginDate = this.otherForm.feeBeginDate
                if (feeBeginDate)
                  return time.getTime() < new Date(feeBeginDate).getTime()
              }
            },
          },
          {
            title: '备注',
            type: 'textarea',
            property: 'remark',
            placeholder: '备注',
            show: true,
          },
        ],
        dialogForm: {
          takeReturnProjectId: ''
        },
        result: {},
        tableFeeCountMap: {},//两个表格加起来的每一条数据对应的笔数，键值对为'orderId'：'笔数' ，
        tableFeeIdsMap: {},//未交费列表表格每一条数据对应的勾选费用，键值对为'orderId'：'明细ids'
        obj: {
          amount: 0,
          feeNum: 0,
          unpay: 0,
          pay: 0,
          payNum: 0,
          unpayNum: 0,
          contractId: 0,
          isConfirm: 0,
          refundStatus: 0,
          relationOrderId:0,
          makeUpItemIds: [],
          // operUser: 0,
          otherItems: [],
          refundItems: [],
          // rentRefundOrder: {
          //   amount: 0,
          //   contractId: 0,
          //   isConfirm: 0,
          //   makeUpItemIds: [],
          //   operUser: 0,
          //   otherItems: [],
          //   refundItems: [],
          //   rentRefundOrder: ''
          // }
        },
        index1: '',//table1的每一行index
        index2: '',//table2的每一行index
        formRules: {
          takeReturnProjectId: vxRule(true, '', "blur", "收费项目不能为空"),
        },
        formRules2: {
          // takeReturnProjectId: vxRule(true, '', "blur", "收费项目不能为空"),
          // payAmount: vxRule(true, '', "blur", "金额不能为空"),
        },

      }
    },
    computed: {},
    created() {
      this.form.contractId = this.$route.query.contractId
      this.refundDetail()
      this.getOptionData()
    },
    components: {ETable, EButton, EForm, infoList, EDialog, contractDetail},
    beforeMount() {

    },
    methods: {
      //公共正则校验
      validate(data, reg, msg, fn) {
        if (!reg()) {
          this.$message.error(msg)
          fn()
        }
      },
      showMore() {
        this.isMore = this.isMore ? false : true
      },
      // 关闭交费列表
      paycancelDialog() {
        this.paydialogVisible = false
        // this.$refs.form.$refs.form.resetFields()
      },
      // 关闭未交费列表
      unpaycancelDialog() {
        this.unpaydialogVisible = false
      },
      showPayList(tableData, index) {
        this.paydialogVisible = true
        this.payOrdersItems = tableData
        this.index1 = index

        let orderId
        tableData.forEach((item, index) => {
          orderId = item.orderId
        })
        this.obj.refundItems.forEach(item => {
          if(item.relationOrderId==orderId){
            this.form.refundStatus = item.refundStatus
          }
        })

      },
      showUnPayList(tableData, index) {
        this.unpaydialogVisible = true
        this.unpayOrdersItems = tableData
        this.index2 = index
        let that = this
        tableData.forEach((item, index) => {
          this.tempSelectData.forEach((sub) => {
            sub.forEach((l) => {
              if (item.orderItemId == l.orderItemId) {
                that.$nextTick(() => {
                  that.$refs['unpayDetailTable'].$refs['table'].toggleRowSelection(item, true)

                })
                that.$forceUpdate()
              }
            })
          })
        })
      },
      // 交费列表点击确定
      payhandleClick() {
        this.obj.payNum = 0
        this.obj.pay = 0

        if(this.form.refundStatus=='0'){
          this.$message.success("请选择退费方式");
          return;
        }
        
        //this.obj.refundItems = []
        this.payOrdersItems.forEach(item => {
          if (item.returnAmount != '') {
            this.form.relationOrderId = item.orderId;
            var oldItem = this.obj.refundItems.find(obj1 => obj1.itemId == item.orderItemId);
            if(oldItem!=null){
              oldItem.returnAmount = item.returnAmount;
            }else{
              // 将退款明细id和金额存入refundItems
              this.obj.refundItems.push({
                itemId: item.orderItemId,
                returnAmount: item.returnAmount,
                refundStatus: this.form.refundStatus,
                relationOrderId: item.orderId
              })
            }
          }
        })

        this.obj.refundItems.forEach(item => {
          this.obj.pay += parseFloat(item.returnAmount)
          this.obj.pay = math.round(this.obj.pay, 2)
          this.obj.payNum++
        })
        //该行数据拥有的笔数returnNum
        this.paydialogVisible = false
        this.getRefundNum()
      },
      // 未交费列表交费点击确定
      unpayhandleClick() {
        this.unpaydialogVisible = false
        //关闭弹窗时，重新计算amount
        this.obj.unpay = 0
        this.obj.unpayNum = 0
        this.obj.amount = 0
        this.obj.feeNum = 0
        this.obj.makeUpItemIds = []
        this.tempSelectData.forEach((item, index) => {
          item.forEach((sub, index) => {
            this.obj.unpay += parseFloat(sub.payAmount)
            this.obj.unpayNum++
            this.obj.makeUpItemIds.push(sub.orderItemId)
          })
        })
        this.getRefundNum()
      },
      handleSelect(data) {
        this.tempSelectData[this.index2] = data
      },
      // 其他收费项
      getRefundNum(index) {
        // 金额
        let result = 0;
        this.obj.amount = 0
        this.obj.feeNum = 0
        // 单独计算收费项金额
        this.otherItems.forEach(item => {
          result += parseFloat(item.payAmount)
        });
        this.obj.amount = result + this.obj.pay + this.obj.unpay
        //取两位小数
        this.obj.amount = math.round(this.obj.amount, 2)
        this.obj.feeNum += this.otherItems.length + this.obj.payNum + this.obj.unpayNum

      },
      //费用录入收费项下拉列表
      async getOptionData() {
        let res = await Http.gettakereturnprojectLabel({
          status: 1
        })
        if (res.code == 200) {
          this.options = res.data
        }
      },
      //获取合同退租详情
      async refundDetail() {
        let res = await Http.refundDetail({id: Number(this.form.contractId)})
        if (res.code == 200) {
          this.payOrders = res.data.payOrders
          this.unpayOrders = res.data.unpayOrders
          this.form.contractId = this.$route.query.contractId
        }
      },
      // 添加收费项
      onSummit(otherForm, takeReturnProjectId) {
        this.$refs['form1'].validate((valid, object) => {
          if (valid) {
            this.otherItems.push({
              feeBeginDate: otherForm.feeBeginDate,
              feeEndDate: otherForm.feeEndDate,
              payAmount: otherForm.payAmount,
              remark: otherForm.remark,
              takeReturnProjectId: takeReturnProjectId,
              takeReturnProjectName: otherForm.takeReturnProjectName,
            })
          } else {
            return false;
          }
        })
      },
      // 移除收费项
      remove(index) {
        this.otherItems.splice(index, 1)
        this.getRefundNum()
      },
      saveData() {
        this.$refs['form'].validate((valid, object) => {
          if (valid) {
            this.obj.refundItems.forEach(item => {
              if(parseFloat(item.returnAmount)==0){
                //this.removeArray(this.obj.refundItems, item.itemId);
              }
            })

            this.rentRefund()
          } else {
            return false;
          }
        })
      },
      /** 
       * 从数组中删除指定对象
       *  _arr:数组
       *  _obj:需删除的对象
       * */
      removeArray(_arr, itemId) {
        let length = _arr.length
        for (let i = 0; i < length; i++) {
          if (_arr[i].itemId === itemId) {
            if (i === 0) {
              _arr.shift() //删除并返回数组的第一个元素
              return _arr
            } else if (i === length - 1) {
              _arr.pop()  //删除并返回数组的最后一个元素
              return _arr
            } else {
              _arr.splice(i, 1) //删除下标为i的元素
              return _arr
            }
          }
        }
      },
      checkExist(strs, str){
        let tempStrs = strs+""
        let result = false
        if(tempStrs.indexOf(",")==-1){
          if(strs==str){
            result = true
          }
        }else{
          let arrs = tempStrs.split(",");
          if(arrs!=null&&arrs.length>0){
            for(let i=0; i<arrs.length; i++){
              if(arrs[i]==str){
                result = true
                break
              }
            }
          }
        }
        return result
      },
      //更新合同
      async rentRefund() {
        //this.setDisabled(true)
        this.obj.otherItems = this.otherItems
        this.obj.isConfirm = this.form.isConfirm
        //默认全退
        this.obj.refundStatus = 2

        let relationOrderIds = "";
        if(this.obj.refundItems!=null&&this.obj.refundItems.length>0){
          for (let i = 0; i < this.obj.refundItems.length; i++) {
            if(this.obj.refundItems[i].refundStatus==='1'){
              //只要有一个退的就部分退
              this.obj.refundStatus = 1
              //只有一个履约保证金，后面需要提示用户一下
              relationOrderIds = this.obj.refundItems[i].relationOrderId
              break

              //获得退款订单ID串
              if(relationOrderIds.length==0){
                relationOrderIds = this.obj.refundItems[i].relationOrderId
              }else{
                if(!this.checkExist(relationOrderIds, this.obj.refundItems[i].relationOrderId)){
                  relationOrderIds = relationOrderIds + "," + this.obj.refundItems[i].relationOrderId
                }
              }

            }
          }
        }

        if((relationOrderIds+"").length>0){
          if(!confirm("目前系统部分退费只能针对包含履约保证金的订单")){
            return;
          }
        }

        this.obj.relationOrderId = relationOrderIds
        //alert(JSON.stringify(this.obj.refundItems))
        //alert(relationOrderIds)
        //return 

        //this.obj.refundStatus = this.form.refundStatus
        //this.obj.relationOrderId = this.form.relationOrderId

        this.obj.contractId = this.form.contractId
        let {
          otherItems,//其他收费项
          isConfirm,
          refundStatus,
          relationOrderId,
          contractId,
          amount,
          feeNum,
          makeUpItemIds,//补缴
          refundItems//退款
        } = this.obj

        //alert(refundStatus);
        //return;

        let res = await Http.rentRefund({
          otherItems,
          isConfirm,
          refundStatus,
          relationOrderId,
          contractId,
          amount,
          feeNum,
          makeUpItemIds,
          refundItems
        })
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.goBack()
          //this.setDisabled(false)
        }
      },
      //商户经营范围下拉
      async businessscopeconfigLabel() {
        let res = await Http.businessscopeconfigLabel()
        if (res.code == 200) {
          this.formColumns[3].options = res.data
        }
      },
    },
    watch: {}
  }
</script>

<style lang="scss" scoped>
    .title {
        font-weight: bold;
        padding-left: 10px;
        border-left: 2px solid #4d68ff;
        margin-bottom: 20px;
    }

    .small-title {
        margin-left: 10px;
        margin-top: 20px;
    }

    .box-height {
        height: 100px;
        overflow: hidden;
        transition: max-height 2s;
    }

    .box-height.active {
        height: auto;
    }

    .el-input.el-input--small {
        width: 220px;
    }

    .el-textarea.el-input--small {
        width: 350px;
    }
</style>
